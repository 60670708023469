import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import { primary } from 'src/styles/newColors'

export const Section = styled.section`
  .bg-form {
    background-color: ${primary[500]};

    @media ${device.desktopLG} {
      width: 388px;
    }

    @media ${device.desktopXL} {
      width: 424px;
    }
  }

  h1 {
    color: ${primary[500]};
  }
  
  @media ${device.tablet} {
    height: 814px;
  }

  @media ${device.desktopLG} {
    height: 784px;
  }

  @media ${device.desktopXXL} {
    background-size: 786px;
  }
`
