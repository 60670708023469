import React from 'react'
import ImageWebp from 'src/components/ImageWebp'
import Newsletter from 'src/components/FormNewsletter'

import { Section } from './style'

const Hero = () => {
  return (
    <Section className='py-5 d-flex' role='img' aria-label='Cliente Inter Empesas sorrindo enquanto segura seu cartão PJ e acessa seu notebook.'>
      <div className='container'>
        <div className='row align-items-center'>
          <div className='col-12 col-md-6 col-lg-5 offset-lg-1 w-100 d-flex justify-content-center order-md-last'>
            <ImageWebp
              pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/email-mkt/image.webp'
              altDescription='Pessoa simpática de camisa laranja se cadastrando na news do Inter.'
              arrayNumbers={[ 316, 321, 374, 478 ]}
            />
          </div>
          <div className='col-12 col-md-6 pr-xl-0'>
            <h1 className='fs-40 fs-md-48 fs-lg-48 lh-44 lh-md-52 fw-500 mt-3'>
              News do Inter
            </h1>
            <p className='fs-32 fs-md-40 lh-35 lh-md-44 text-grayscale--500 fw-700 font-citrina'>
              <span className='d-lg-block d-xl-inline'>Rápida de ler,</span> fácil de gostar
            </p>
            <p className='fs-14 fs-md-18 lh-16 lh-md-22 text-grayscale--400 mt-4 mt-xl-3'>
              <span className='d-xl-block'>Novidades do mercado financeiro, inovação, tecnologia</span>
              <span className='d-xl-block'>e as notícias mais recentes do Inter. Tudo em uma</span> newsletter descontraída e gratuita.
            </p>
            <div className='bg-form rounded-5 mt-3 mt-md-4'>
              <Newsletter
                name='form-newsletter'
                section='dobra_01'
                sectionName='News do Inter - Rápida de ler, fácil de gostar'
                elementName='Assinar'
              />
            </div>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default Hero
