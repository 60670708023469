/* eslint-disable react/jsx-closing-tag-location */
import React, { useState, ChangeEvent } from 'react'
import { useForm } from 'react-hook-form'
import axios from 'axios'

// import { sendCDPFormData } from 'src/shared/cdp'

import Message from './Message'

import { Form, Button } from './style'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

type NewsletterProps = {
  name: string;
  section: string;
  sectionName: string;
  elementName: string;
}

const Newsletter = ({ name, section, sectionName, elementName }: NewsletterProps) => {
  const { register, errors, handleSubmit }: Pick<ReturnType<typeof useForm>, 'register' | 'errors' | 'handleSubmit' | 'setValue'> = useForm()
  const [ loading, setLoading ] = useState(false)
  const [ acceptedTerms, setAcceptedTerms ] = useState(false)
  const [ status, setStatus ] = useState('')
  const [ sendDatalayerEvent ] = useDataLayer()

  function handleCheck (event: ChangeEvent<HTMLInputElement>) {
    const inputValue = event.currentTarget.checked
    setAcceptedTerms(inputValue)
  }

 const sendNewsletterFrom = async (name: string, email: string) => {
  try {
    const formatData = [
      {
        nome: name,
        email: email,
        aceite: true,
        conteudo01: 'Site Institucional',
        conteudo02: window.location.href,
      },
    ]

    const { data } = await axios.post(`${process.env.NEWSLETTER_ENDPOINT}`, formatData, {
      headers: {
        'Content-Type': 'application/json',
      },
    })

    if (data.success) {
      return true
    } else {
      return false
    }
  } catch (e) {
    return false
  }
}

  const sendForm = async (data: Record<string, string>) => {
    setLoading(true)
    const success = await sendNewsletterFrom(data.nomeCompleto, data.email)
    if (success) {
      setStatus('success')
      sendDatalayerEvent({
        section: section,
        section_name: sectionName,
        element_name: elementName,
        element_action: 'submit',
      })
    } else {
      setStatus('error')
    }
    setLoading(false)
  }
  return (
    status !== '' ? <Message status={status} setStatus={setStatus} sectionName={sectionName} section={section} />
      : <Form className='px-3 pt-4 pb-3' onSubmit={handleSubmit(sendForm)}>
        <div className='row pl-0 d-flex'>
          <div className='col-12 mb-3'>
            <h2 className='fs-16 fs-md-18 lh-20 lh-md-22 text-white text-center'>Já somos mais de 60 mil leitores!</h2>
            <p className='fs-14 lh-17 text-white text-center'><span className='d-block'>Preencha os campos abaixo e faça parte</span> dessa comunidade você também.</p>
            <input
              ref={register({
                required: 'O nome é obrigatório',
              })}
              name='nomeCompleto'
              id='nomeCompleto'
              type='text'
              placeholder='Nome'
            />
            { errors.nomeCompleto && <p className='fs-12 text-white my-2'>{errors.nomeCompleto.message}</p> }
          </div>
          <div className='col-12'>
            <input
              ref={register({
                required: 'E-mail obrigatório',
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: 'E-mail inválido',
                },
              })}
              name='email'
              id='email'
              type='text'
              placeholder='E-mail'
            />
            { errors.email && <p className='fs-12 text-white my-2'>{errors.email.message}</p> }
          </div>
          <div className='col-12 checkbox'>
            <input
              ref={register()}
              id={name}
              name={name}
              type='checkbox'
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleCheck(event)}
            />
            <label className='formLabelCheck fs-12 lh-16 text-white' htmlFor={name}>
              Autorizo o envio de conteúdo sobre investimentos, educação financeira e novos produtos financeiros e estou de acordo com a
              <a
                className='fw-700 text-white' title='Acessar Política de Privacidade do Banco Inter'
                href='https://inter.co/politica-de-privacidade/'
                target='_blank' rel='noreferrer'
              > Política de Privacidade
              </a> do Inter.
            </label>
          </div>
          <div className='col-12 mt-3'>
            <Button
              type='submit'
              title='Assinar'
              disabled={!acceptedTerms || loading}
              className='w-100'
            >
              {loading ? 'Enviando...' : 'Assinar'}
            </Button>
          </div>
        </div>
      </Form>
  )
}

Newsletter.defaultProps = {
  name: 'aceite-termos',
}

export default Newsletter
