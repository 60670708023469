import React from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import Success from 'src/assets/images/img-success.png'
import Error from 'src/assets/images/erro.png'

import { Msg } from './style'

const objectStatus = {
  success: Success,
  error: Error,
}
interface IMessageProps {
  status: string;
  setStatus: (value: string) => void;
  sectionName: string;
  section: string;
}
interface IInfo {
  [params: string]: {
    title: string;
    description: string;
    button: string;
    handleCallbackButton: () => void;
   };
}

const Message = ({ status, setStatus, sectionName, section }: IMessageProps) => {
  const [ sendDatalayerEvent ] = useDataLayer()

  const handleDataLayer = (statusMsg: string) => {
    sendDatalayerEvent({
      section: section,
      element_action: 'click button',
      element_name: `${statusMsg} - ${'Ok ENTENDI'}`,
      section_name: sectionName,
    })
  }

  const info: IInfo = {
      success: {
        title: 'Obrigado por se cadastrar!',
        description: 'O material será enviado para o seu e-mail',
        button: 'Ok, entendi',
        handleCallbackButton: () => {
          setStatus('')
          handleDataLayer('Sucesso')
        },
      },
      error: {
        title: 'No momento não foi possível enviar o formulário',
        description: 'Por favor, tente novamente mais tarde',
        button: 'Ok, entendi',
        handleCallbackButton: () => {
          setStatus('')
          handleDataLayer('Erro')
        },
      },
    }

    return (
      <Msg className='col-12 text-center px-3 py-4 pt-form-center'>
        <div className='col-12 my-3 form-center'>
          <img src={objectStatus[status as keyof typeof objectStatus]} alt={info[status].title} />
        </div>
        <h4 className='fs-18 lh-24 mb-2 fw-600'>{info[status].title}</h4>
        <p className='fs-14 lh-20 pt-form-center mb-4'>
          {info[status].description}
        </p>
        <button
          className='btn btn-lg bg-orange--base text-white text-uppercase fw-600 fs-14 lh-17'
          title={info[status].button}
          onClick={info[status].handleCallbackButton}
        >{info[status].button}
        </button>
      </Msg>
  )
}

export default Message
